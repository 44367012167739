import useTheme from '../../hooks/useThemes'
import React, { Fragment, MutableRefObject, useMemo, useRef, useState } from 'react'
import { IList } from 'types'
import AttachFileIcon from '../../../Messenger/icons/AttachFileIcon'
import rightArrow from '../../../../assets/right-arrow.svg'
import { SpinLoading } from '../../views/MessengerMessages'
import ToolTip from '../Tooltip/Tooltip'

interface IProps {
  allowImages: boolean,
  current?: IList,
  onSubmit: (value: FormData, callback: () => void) => void
  file?: File | null
  updateFile: (file: File | null) => void
  isLoading?: boolean
  maxFileSize: string
}

function AllowDocuments ({ allowImages, attachFile, handleFile, closedChat, maxFileField }: { allowImages: boolean, attachFile: MutableRefObject<HTMLInputElement | null>, handleFile: (e: React.ChangeEvent<HTMLInputElement>) => void, closedChat: boolean, maxFileField: string }) {
  if (allowImages) {
    return (
      <Fragment>
        <input
          type="file"
          size={1}
          className="messenger__messages-send--file"
          ref={attachFile}
          onChange={handleFile}
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
        />

        <ToolTip text={`Tamanho máximo: ${maxFileField}`}>
          <AttachFileIcon
            className={`messenger__messages-send__attach ${closedChat ? 'messenger__messages-send__attach--disabled' : ''}`}
            onClick={() => !closedChat ? attachFile.current?.click() : null}
          />
        </ToolTip>
      </Fragment>
    )
  }

  return <></>
}

function SendMessageForm ({ allowImages, current, onSubmit, file, updateFile, isLoading, maxFileSize }: IProps) {
  const [formHeight, setFormHeight] = useState<number>(100)
  const [sending, setSending] = useState<boolean>(false)

  const inputRef: MutableRefObject<HTMLTextAreaElement | null> = useRef(null)
  const attachFile: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const closedChat = useMemo(() => current?.status.current.state === 'final', [current])

  const { theme } = useTheme()

  function correctTextAreaHeight (styleConfig: { height: number, overflow: string }) {
    const textarea = inputRef.current
    if (textarea) {
      textarea.style.height = `${styleConfig.height}px`
      textarea.style.overflowY = styleConfig.overflow
    }
  }

  function resetHeights () {
    const inicialFormHeight = 100
    const inicialTextAreaHeight = 50
    setFormHeight(inicialFormHeight)
    correctTextAreaHeight({ height: inicialTextAreaHeight, overflow: 'hidden' })
  }

  function adjustFormHeight (textareaRef: typeof inputRef.current) {
    if (textareaRef) {
      const initialHeight = 50
      const maxHeight = 80
      const newHeight = textareaRef.scrollHeight

      if (newHeight > maxHeight) {
        correctTextAreaHeight({
          height: maxHeight,
          overflow: 'auto'
        })
        return maxHeight + initialHeight
      }

      correctTextAreaHeight({
        height: initialHeight,
        overflow: 'auto'
      })
      return initialHeight * 2
    }

    return formHeight
  }

  function handleTextareaChange () {
    const textarea = inputRef.current
    setFormHeight(adjustFormHeight(textarea))
  }

  function handleSubmit (e?: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault()
    if (sending) return
    setSending(true)

    const data = new FormData()
    if (file) data.append('file', file)
    if (inputRef.current?.value) data.append('message', inputRef.current?.value)

    onSubmit(data, () => {
      if (inputRef.current) inputRef.current.value = ''
      if (file) updateFile(null)
      setSending(false)
      resetHeights()
    })
  }

  function handleFile (e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.files) {
        const file = e.target.files[0]
        if (file?.type?.includes('image/') || file?.type?.includes('application/') || file?.type === 'application/msword' || file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          updateFile(e.target.files[0])
        }
      }
    }

  return (
    <form
      onSubmit={handleSubmit}
      className="messenger__messages-send--form"
      style={{
        height: `${formHeight}px`,
        maxHeight: '150px'
      }}
    >
      <div
        className='messenger__messages-send__input-container'
        style={{
          background: theme.inputBg,
          border: theme?.chatInputBorder ? `1px solid ${theme?.chatInputBorder}` : ''
        }}
      >
        <textarea
          onChange={handleTextareaChange}
          placeholder={closedChat ? 'Conversa finalizada' : 'Digite uma mensagem'}
          ref={inputRef}
          onKeyDown={(e) => { 
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              handleSubmit() 
            }
          }}
          className={`messenger__messages-send__input ${closedChat || sending ? 'messenger__messages-send__input--disabled' : ''} `}
          disabled={closedChat || sending}
          style={{
            backgroundColor: theme?.inputBg,
          }}
        />

        <AllowDocuments 
          allowImages={allowImages}
          handleFile={handleFile}
          attachFile={attachFile}
          closedChat={closedChat}
          maxFileField={maxFileSize}
        />
      </div>

      {isLoading ? (
          <div className="messenger__messages-sending">
            <SpinLoading />
          </div>)
        : <button
        type="submit"
        className={`messenger__messages-send__button ${closedChat || sending ? 'messenger__messages-send__button--disabled' : ''}`}
        disabled={closedChat || sending}
        style={{
          backgroundColor: theme?.buttonPrimary
        }}
      >
        <img
          alt='Icone para a direita'
          height={25}
          width={25}
          className="ml-1"
          src={rightArrow}
        />
      </button>}
    </form>
  )
}

export default SendMessageForm
