import React from 'react'
import DownloadMinimalistIcon from '../../icons/DownloadMinimalistIcon'
import { IMessagesBalloon } from 'types'
import RenderFileIcon from '../RenderFileIcon/RenderFileIcon'

export function formatFileSize(size: number = 0) {
  if (size % 1 === 0) {
    return `${size.toFixed(0)} MB`
  }
  return `${size.toFixed(2)} MB`
}

function DocMessage({ file }: IMessagesBalloon) {
  const fileType = file?.location?.split('.').pop()?.toUpperCase()
  const fileName = file && file.originalname
  const filePages = file && file.pages
  const fileSize = file && formatFileSize(file?.size)


  return (
    <div className="messenger__messages-row--sender">
      <span>
        {(
          <div className="messenger__file-card">
            <div className="messenger__file-icon">
              <RenderFileIcon fileType={file?.mimetype} />
            </div>
            <div className="messenger__file-details">
              <p className="messenger__file-name">{fileName}</p>
              <p className="messenger__file-info">
                {filePages && filePages + ' página(s) •'} {fileSize} • {fileType}
              </p>
            </div>
            <a
              href={file?.location}
              target="_blank"
              rel="noopener noreferrer"
              className="messenger__file-download"
              style={{ color: 'blue' }}
            >
              <DownloadMinimalistIcon width="24" height="24" color="white" />
            </a>
          </div>
        )}
      </span>
    </div>
  )
}

export default DocMessage
