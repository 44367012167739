import TrashIcon from '../../icons/TrashIcon'
import React, { useMemo } from 'react'
import useTheme from '../../hooks/useThemes'

interface IProps {
  fileList: File[]
  onUpdateFile: (files: File) => void
  label: string
  onRemoveFile: (files: File) => void
  maxSize: string
  loading: boolean
}

function InputFile({ fileList, onUpdateFile, label, onRemoveFile, maxSize, loading }: IProps) {
  const { theme } = useTheme()

  const validateFileType = useMemo(
    () => (allowedTypes: string[], file?: File) => {
      if (file?.type) {
        return allowedTypes.includes(file.type)
      }
      const extension = file?.name.split('.').pop()?.toLowerCase()
      return extension ? allowedTypes.includes(`.${extension}`) : false
    },
    []
  )

  const onUpload = () => {
    const input = document.querySelector('.file-input__input') as HTMLElement
    input?.click()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]

    const isAllowedType = validateFileType(
      ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      file
    )
    if (!isAllowedType) {
      console.error('Escolha uma imagem JPG/PNG, PDF ou arquivo DOC/DOCX.')
      return false
    }

    if (file) onUpdateFile(file)
    return true
  }

  const removeItem = (file: File) => {
    if (fileList.some((item) => item === file)) {
      onRemoveFile(file)
    }
  }

  return (
    <>
      <div className="file-input">
        <label style={{ color: theme.newChatFormTexts }} htmlFor="" className="file-input__label">
          {label}
        </label>
        <div
          className="file-input__container"
          style={{
            backgroundColor: theme.inputBg,
            color: theme.newChatFormTexts,
          }}
        >
          <input
            className="file-input__input"
            type="file"
            id="docpicker"
            hidden
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
            onChange={(event) => onChange(event)}
          />

          <h4>Escolha o arquivo</h4>
          <button
            className="file-input__button"
            type="button"
            onClick={onUpload}
            style={{
              background: theme?.buttonPrimary,
              color: theme?.buttonPrimaryText,
            }}
          >
            Escolher arquivo
          </button>

          {loading ? '' : <p>Só são aceitos arquivos no formato .png, .jpg, .pdf, .doc ou .docx (Tamanho máximo: {maxSize})</p>}
        </div>
      </div>

      <div>
        {fileList &&
          fileList.map((item) => (
            <div
              className="file-input__list"
              key={item.name}
              style={{
                backgroundColor: theme.inputBg,
                color: theme.newChatFormTexts,
              }}
            >
              <span className="file-input__list--file-name">{item.name}</span>
              <button className="file-input__delete" onClick={() => removeItem(item)}>
                <TrashIcon color={theme.newChatFormDeleteFileButton || 'red'} />
              </button>
            </div>
          ))}
      </div>
    </>
  )
}

export default InputFile
