import React, { FormEvent, useState } from 'react'

import InputFile from '../components/InputFile/InputFile'
import Select from '../components/Select/Select'
import Textarea from '../components/TextArea/TextArea'
import useTheme from '../hooks/useThemes'
import CloseIcon from '../icons/CloseIcon'
import { IOption } from 'types'


interface IProps {
  submitNewChat: (values: FormData) => void
  cancelNewChat: () => void
  reasons: Array<IOption>
  products: Array<IOption>
  loading: boolean
  maxFileSize: string
  loadingResources: boolean
}

function NewChatForm ({ submitNewChat, cancelNewChat, reasons, products, loading, maxFileSize, loadingResources }: IProps) {
  const [reason, setReason] = useState<string>(reasons?.[0]?.value)
  const [product, setProduct] = useState<string>(products?.[0]?.value)
  const [message, setMessage] = useState<string>()
  const [fileList, setFileList] = useState<File[]>([])

  const { theme } = useTheme()

  const onSubmit = (event: FormEvent) => {
    const data2 = new FormData()
    event.preventDefault()

    fileList.forEach(file => {
      data2.append(`images`, file, file.name)
    })

    if (reason) data2.append('reason', reason)
    if (product) data2.append('product', product)

    if (message) data2.append('message', message)
    submitNewChat(data2)
  }

  const onUpdateFile = (file: File) => {
    setFileList(files => [...files, file])
  }

  const onRemoveFile = (file: File) => {
    setFileList(fileList.filter((item: File) => item !== file))
  }

  return (
    <div
      className="messenger__new-chat-form"
      style={{
        backgroundColor: theme.newChatFormBg,
        borderRadius: theme.newChatFormRadius,
      }}
    >
      <div className="messenger__new-chat-form-header">
        <h2 className="messenger__new-chat-form-title">
          <b style={{ color: theme.newChatFormTexts }} >Novo Chamado</b>
        </h2>

        <button onClick={cancelNewChat} className="messenger__new-chat-form-close">
          <CloseIcon />
        </button>
      </div>

      <h3
        className='messenger__disclaimer'
        style={{
          borderColor: theme.disclaimerPrimaryColor,
          color: theme.disclaimerTextColor,
          backgroundColor: theme.disclaimerSecondaryColor
        }}
      >
        Seu chamado será respondido o mais breve possível! Caso você se ausente do chamado pelo período de <b>uma hora</b> o mesmo será automaticamente fechado.
      </h3>

      <form 
        onSubmit={onSubmit}
        className="messenger__new-chat-fields"
      >
        <Select
          onChange={(value) => setReason(value)}
          options={reasons}
          placeholder='Selecione um motivo para o chamado'
          name="reasons"
          value={reason}
          label="Motivos"
        />

        <Select
          onChange={(value) => setProduct(value)}
          options={products}
          placeholder='Selecione o relacionado ao erro'
          value={product}
          name="product"
          label="Produto (opcional)"
        />

        <InputFile
          fileList={fileList}
          onUpdateFile={onUpdateFile}
          onRemoveFile={onRemoveFile}
          label='Documento (opcional)'
          maxSize={maxFileSize}
          loading={loadingResources}
        />

        <Textarea
          onChange={(value) => setMessage(value)}
          placeholder="Digite sua mensagem aqui"
          name="message"
          label="Mensagem"
        />

        <button
          className={`w-100 ${!loading ? 'messenger__new-chat-form-send' : 'messenger__new-chat-form-send messenger__new-chat-form-send--disabled'}`}
          disabled={loading}
          type="submit"
          style={{
            backgroundColor: !loading ? theme.buttonPrimary : '#ccc',
            color: theme.buttonPrimaryText
          }}
        >
          Enviar novo chamado
        </button>
      </form>
    </div>
  )
}

export default NewChatForm
