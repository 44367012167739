import React, { Fragment } from 'react'
import { IList } from 'types'
import ChatTabs from '../components/ChatTabs/ChatTabs'
import ChatButtons from '../components/ChatButton/ChatButton'
import SearchInput from '../components/SearchInput/SearchInput'
import MessengerListItem, { activeItem } from './MessengerListItem'
import useTheme from '../hooks/useThemes'
import EmptyIcon from '../icons/EmptyIcon'
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll'

interface IMessengerList {
  affix?: React.ReactElement
  loading: boolean
  conversations: IList[]
  currentId?: string
  failed?: boolean
  showBadge: boolean
  tab?: 'list' | 'messages'
  className?: string
  onClick: (item: IList) => void
  searchChat: (search: string, key: string) => void
  searchKeys: { label: string, value: string }[]
  messegerView: 'empty' | 'messages' | 'newChat'
  helpDesk: boolean
  handleChangeTickets: (tab: string) => void
  loadingSearch: boolean
  chatListTabs: { value: string, label: string }[]
  chatButtons: {
    label: string
    onClick: () => void
    type: string
    canSee: boolean
    disabled?: boolean
  }[]
  canSeeTimer: boolean
  hasNext: boolean
  onIntersect: () => void
  sliceCount: number
  currentViewerId: string
}

const ListLoading = () => {
  const {theme} = useTheme()
  return (
    <Fragment>
      {new Array(5).fill('').map((_, idx) => (
        <button
          key={`asideSkeleton${idx}`}
          style={{
            background: theme.listItemHover,
            borderBottom: idx < 4 ? `1px solid ${theme.borderColor}` : '0',
          }}
          className="messenger__aside-skeleton"
        />
      ))}
    </Fragment>
  )
}

function MessengerList ({
  affix,
  conversations,
  tab,
  className = '',
  loading,
  failed,
  showBadge,
  currentId,
  onClick,
  searchChat,
  searchKeys,
  messegerView,
  helpDesk,
  handleChangeTickets,
  chatListTabs,
  chatButtons,
  loadingSearch,
  canSeeTimer,
  hasNext,
  onIntersect,
  sliceCount,
  currentViewerId
}: IMessengerList) {
  const { theme } = useTheme()

  return (
    <aside
      className={`messenger__aside ${className} ${tab === 'messages' ? 'messenger__mobile-hide' : ''}`}
      style={{
        border: `1px solid ${theme.borderColor}`,
        borderRadius: '20px 0 0 20px',
        background: theme.asideBg
      }}
    >
      <div className="messenger__buttons-container">
        <ChatButtons
          chatButtons={chatButtons}
          messegerView={messegerView}
          removeActiveItem={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            activeItem(e, 'messenger__aside-list-item', 'messenger__aside-list-item--active')
          }}
        />

        {helpDesk &&
          <SearchInput
            searchKeys={searchKeys}
            searchChat={searchChat}
            loadingSearch={loadingSearch}
          />
        }
      </div>

      {affix && (
        <div className="messenger__aside-header">
          {React.cloneElement(affix)}
        </div>
      )}

      <ChatTabs
        chatListTabs={chatListTabs}
        handleChangeTickets={handleChangeTickets}
      />

      <div className="messenger__aside-list">
        <InfiniteScroll
          root={document.querySelector('.messenger__aside-list')}
          loading={loading}
          loadingCover={<ListLoading />}
          more={hasNext}
          fetch={onIntersect}
        >
          {Array.isArray(conversations) && conversations.length > 0 && conversations.slice(0, sliceCount).map((item, idx) => {
            return (
              <div
                key={item?._id}
                className={`messenger__aside-item-container ${showBadge ? 'messenger__active-badge' : ''}`}
              >
                <MessengerListItem
                  canSeeTimer={canSeeTimer}
                  currentId={currentId}
                  item={item}
                  onClick={onClick}
                  isLastItem={idx === (conversations.length - 1)}
                  currentViewerId={currentViewerId}
                />
              </div>
            )
          })}
        </InfiniteScroll>

        {failed || (conversations?.length === 0 && !loading) && (
          <div className="messenger__aside-empty">
            <EmptyIcon />
            <p>Nenhuma conversa encontrada</p>
          </div>
        )}
      </div>
    </aside>
  )
}

export default MessengerList
