import React from 'react'
import FileDoc from '../../icons/DOCFileIcon'
import FilePdf from '../../icons/PDFFileIcon'

const RenderFileIcon = ({fileType, b64}: {fileType?: string, b64?: string}) => {

    if (fileType === 'application/pdf') {
      return <FilePdf width="40" height="40" />
    } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return <FileDoc width="40" height="40" />
    } else {
      return <img
          alt='arquivos enviados pelo chat agx'
          src={b64}
          className="images-container__image"
        />
    }
  }

export default RenderFileIcon