import React, { useState, useMemo, useEffect } from 'react'
import { IList } from 'types'
import { SlaDates, timeAsDayjs } from '../classes/slaCalculations'
import MessengerAvatar from '../components/MessengerAvatar/MessengerAvatar'
import useTheme from '../hooks/useThemes'
import TimerIcon from '../icons/TimerIcon'

interface IListItem {
  onClick: (item: IList) => void
  item: IList
  currentId?: string
  canSeeTimer: boolean
  isLastItem: boolean
  currentViewerId: string
}

export function activeItem (e: React.MouseEvent<HTMLElement, MouseEvent>, defaultClass: string, activeClass: string,) {
  const click = document.querySelectorAll(`.${defaultClass}`)
  click.forEach((item) => {
    item.classList.remove(activeClass)
  })

  e.currentTarget.className += ` ${activeClass} `
}

function MessengerListItem ({ item, onClick, canSeeTimer, currentId, isLastItem, currentViewerId }: IListItem) {
  const [unreadMessages, setUnreadMessages] = useState<typeof item.totalUnreadMessages>(item.totalUnreadMessages)
  const closedChat = useMemo(() => item.status.current.state === 'final' ? 'messenger__aside-list-item--closed' : '', [item])
  const [background, setBackground] = useState<string | undefined>('')
  const [counter, setCounter] = useState<number | null>(null)
  const { theme } = useTheme()
  const isInprogress = item.status?.current?.systemicValue === 'inprogress'

  const otherChatMember = useMemo(
    () => item?.users.find(item => item.uniqueCode !== currentViewerId) || item?.creator,
    [item?.users, currentViewerId]
  )

  useEffect(() => {
    let interval: NodeJS.Timer
    if (isInprogress && canSeeTimer) {
      const sla = new SlaDates(timeAsDayjs(item.currentTime).diff(item.createdAt, 'second'))
      const diference = sla.calculateWorkingTime(
        timeAsDayjs(item.createdAt),
        timeAsDayjs(), { start: 9, end: 18 }
      )

      setCounter(diference)
      if (sla.canUpdateClock(timeAsDayjs())) {
        interval = setInterval(() => {
          setCounter(prev => prev !== null ? prev + 1 : null)
        }, 1000)
      }
    } else {
      setCounter(item.SLATimePassed ?? null)
    }

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setUnreadMessages(item.totalUnreadMessages)
  }, [item])

  function handleClick (e: React.MouseEvent<HTMLElement, MouseEvent>, item: IList) {
    activeItem(e, 'messenger__aside-list-item', 'messenger__aside-list-item--active')
    onClick(item)
  }

  function formatUnreadMessages (unread: number) {
    if (!unread) return 0
    if (unread >= 99) return 99
    return unread
  }

  const onMouseOver = () => {
    setBackground(theme?.listItemHover)
  }

  const onMouseLeave = () => {
    setBackground('')
  }


  const ShowTimer = () => {
    if (counter === null) return null
    const textColor = SlaDates.getColorsByTime(counter)

    return (
      <span
        className='messenger__aside-timer'
        style={{
          color: textColor
        }}
      >
        <TimerIcon color={textColor} />&nbsp;
        <b>{SlaDates.secondsInHours(counter)}</b>
      </span>
    )
  }

  return (
    <div
      className={`messenger__aside-list-item ${closedChat}`}
      onClick={(e) => handleClick(e, item)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={{
        color: theme?.asideFontColor,
        backgroundColor: currentId === item._id ? theme?.active : background,
        borderBottom: !isLastItem ? `1px solid ${theme.borderColor}` : '0'
      }}
    >
      <MessengerAvatar image={otherChatMember?.image}>
        {(otherChatMember && otherChatMember.username) ? otherChatMember.username.slice(0, 1) : ''}
      </MessengerAvatar>

      <div className="messenger__aside-list-item--info">
        <p className="messenger__aside-list-item--title">{otherChatMember?.username}</p>
        {item?.reason && (<p className="messenger__aside-list-item--description">{item.reason}</p>)}
        {canSeeTimer && <ShowTimer />}
      </div>

      <div className="messenger__aside-list-item--additional">
        {`#${item.protocol}`}
        {unreadMessages > 0 && (
          <div className="messenger__aside-list-item--unread">
            <span>{formatUnreadMessages(unreadMessages)}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default MessengerListItem
