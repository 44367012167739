import React, { ReactNode } from 'react';

interface ToolTipProps {
  text: string;
  children: ReactNode;
}

const ToolTip: React.FC<ToolTipProps> = ({ text, children }) => {
  return (
    <div className="tooltip">
      {children}
      <div className="tooltip__text">{text}</div>
    </div>
  );
};

export default ToolTip;
