
import React, { useEffect, useState } from 'react'
import { IImagesContainer } from 'types'
import RenderFileIcon from '../RenderFileIcon/RenderFileIcon'

function ImagesContainer ({ file, onClose }: IImagesContainer) {
  const [fileInfo, setFileInfo] = useState<{ name: string, type: string }>({ name: '', type: '' })

  const [b64, setB64] = useState<string>('')

  useEffect(() => {
    function getBase64 (file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }

    if (file) getBase64(file).then(base64 => setB64(base64 as string))
  }, [file])

  useEffect(() => {
    if (file) {
      setFileInfo({
        name: file.name,
        type: file.type
      })
    }
  }, [file])

  

  return (
    <div className="images-container">
      <div className="images-container__close">
        <button 
          className="images-container__close--icon"
          onClick={onClose}
        />
      </div>

      <div className="images-container__container">
        <div className="images-container__image">
          <RenderFileIcon fileType={fileInfo.type} b64={b64} />
          <span className="images-container__title-img">{fileInfo.name}</span>
        </div>
      </div>
    </div>
  )
}

export default ImagesContainer
