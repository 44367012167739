import useTheme from '../../hooks/useThemes'
import React, { useCallback, useState } from 'react'
import { Iimages, IInconmingMessage } from 'types'
import MessengerAvatar from '../MessengerAvatar/MessengerAvatar'
import ReactSimpleImageViewer from '../../../../../node_modules/react-simple-image-viewer'
import DocMessage from '../DocMessage/DocMessage'

interface IMessagesWithFile {
  file: Iimages | { contentType: string; location: string; mimetype: string; originalname: string; size?: number; pages?: number; key?: string } | undefined
  message: string
  date: string
  openImageViewer: (index: number) => void
  id: string
  formatDate: (date: string | Date) => string | undefined
  currentImage: number
}

export const MessageImage = ({ ...rest }) => {
  return (
    <div className="messenger__message--file">
      <img 
        {...rest}
        width={200}
        height={200}
        alt="imagem na mensagem"
      />
    </div>
  )
}

const MessageWithFile = ({ file, message, date, openImageViewer, id, formatDate, currentImage }: IMessagesWithFile) => {
  const isImage = (file: { contentType: string; location: string; mimetype: string; originalname: string; size?: number; pages?: number; key?: string } | Iimages | undefined): file is Iimages => !!file?.mimetype?.includes('image/png') || !!file?.mimetype?.includes('image/jpeg')

  return (
    <>
      {isImage(file) ? (
        <MessageImage
          src={file?.location}
          onClick={() => openImageViewer(currentImage)}
        />
      ) : file && (
        <DocMessage
          file={file}
          message={message}
          date={date}
          read={false}
          id={id}
          formatDate={formatDate}
        />
      )}
    </>
  )
}

function IncomingMessage ({ date, message, user, hasFile, file, isSystemMessage, formatDate, id }: IInconmingMessage) {
  const {theme} = useTheme()
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <div 
      className="messenger__messages-row--received"
      id={id}
    >
      {(user.avatar || user.name) && !isSystemMessage && (
        <div className="messenger__message-avatar mr-2">
          <MessengerAvatar image={user.avatar}>{user.name.slice(0, 1)}</MessengerAvatar>
        </div>
      )}

      <div className="messenger__messages-received" style={{background: theme?.messengerIncomerColor}}>
        {!isSystemMessage && <p className="messenger__message-title">{user.name}</p>}

        <span>
          {hasFile && <MessageWithFile file={file} message={message} date={date} openImageViewer={openImageViewer} id={id} formatDate={formatDate} currentImage={currentImage} />}
          {message && <p className="messenger__message">{message}</p>}
          <p className="messenger__message--date">{formatDate(date) ?? new Date(date).toLocaleString()}</p>
        </span>
        
      </div>
      {file && isViewerOpen && 
        <ReactSimpleImageViewer
          src={[file.location]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      }
    </div>
  )
}

export default IncomingMessage
